<script setup>
import Section from '@/Components/Section.vue';
import Heading from '@/Components/Heading.vue';
import Progress from '@/Components/Progress.vue';
import Badge from '@/Components/Badge.vue';
import Loader from '@/Components/Loader.vue';
import { chunks } from "@/Components/Composable/Chunk";

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Virtual } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { formatPrice } from '@/Components/Composable/Monetize.js';

const props = defineProps( {
    profile: Object,
    interval: Number,
} )

// Set projects list
const projects = ref( [] );
const percentage = ref( '0' );
const isMonthlyHours = ref( false );
const amountInBudget = ref( 0 );
const amountOutBudget = ref( 0 );

// Load projects when mounting and subscribe to websocket
onMounted( () => {
    loadProjects();

    // Interval for every 5 minutes
    setInterval(() => {
        loadProjects();
    }, props.interval);

    window.Echo.channel( `dashboard.${props.profile.slug}` ).listen( '.projects', (data) => {
        if (data?.hasChanged) {
            loadProjects();
        }
    } );
} )

// Unsubscribe from webhook when unmounting
onBeforeUnmount( () => {
    window.Echo.channel( `dashboard.${props.profile.slug}` ).stopListening( '.projects' );
} )

/**
 * Load projects from API
 */
const loadProjects = () => {
    axios.get( `/api/${props.profile.slug}/projects` ).then( (response) => {
        const { data } = response;

        projects.value = data.projects;
        percentage.value = data.completedWithinHours;
        isMonthlyHours.value = data.isMonthlyHours;
        amountInBudget.value = data.amountInBudget;
        amountOutBudget.value = data.amountOutBudget;
    } );
}

// Chunk projects for slider
const projectsChunks = computed( () => {
    return chunks( projects.value )
} );
</script>

<template>
    <Section>
        <Heading title="Projecten" :show-subtitle="projects.length > 0" :subtitle="`${amountInBudget} binnen budget / ${amountOutBudget} buiten budget`" class="mb-4" v-if="isMonthlyHours"/>
        <Heading title="Projecten" :show-subtitle="projects.length > 0" :subtitle="percentage + '% binnen de uren voltooid'" class="mb-4" v-else/>

        <div class="flex grow" v-if="projects.length > 0">
            <swiper
                :slides-per-view="1"
                :space-between="0"
                :pagination="{ clickable: true }"
                :grabCursor="true"
                :virtual="true"
                :loop="true"
                :modules="[Pagination, Virtual]">
                <swiper-slide
                    v-for="(chunk, index) in projectsChunks"
                    :key="index"
                    :virtualIndex="index">
                    <table class="min-w-full">
                        <colgroup>
                            <col class="w-full" :class="isMonthlyHours ? 'sm:w-5/12' : 'sm:w-6/12'"/>
                            <col class="lg:w-1/12"/>
                            <col class="lg:w-1/12"/>
                            <col class="lg:w-1/12" v-if="isMonthlyHours"/>
                            <col class="lg:w-4/12"/>
                        </colgroup>
                        <thead>
                            <tr class="text-xs text-left text-gray-400 font-semibold">
                                <th scope="col">Projecten</th>
                                <th scope="col">Besteed</th>
                                <th scope="col">Budget</th>
                                <th scope="col" v-if="isMonthlyHours">Uurtarief</th>
                                <th scope="col">Verhouding</th>
                            </tr>
                        </thead>
                        <tbody v-if="chunk.length">
                            <tr v-for="(project, index) in chunk" :key="index">
                                <td class="whitespace-nowrap py-2.5">
                                    <div class="flex flex-col">
                                        <div class="flex items-center mb-0.5">
                                            <h3 class="text-base font-semibold">{{ project.organization.name }}</h3>
                                            <Badge v-if="project.is_new" type="new" :large="false" class="ml-1">Nieuw!</Badge>
                                        </div>
                                        <span class="text-xs text-gray-500 truncate max-w-[430px]">{{ project.name }}</span>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap py-2.5">
                                    <span class="flex text-xs">{{ project.hours_spent }}</span>
                                </td>
                                <td class="whitespace-nowrap py-2.5">
                                    <span class="flex text-xs">{{ project.hours_budget }}</span>
                                </td>
                                <td class="whitespace-nowrap py-2.5" v-if="isMonthlyHours">
                                    <span class="flex text-xs">{{ formatPrice(project.tariff, true) }}</span>
                                </td>
                                <td class="whitespace-nowrap py-2.5">
                                    <Progress :current="project.hours_spent" :goal="project.hours_budget" :indicator="true" :large="false" :labels="false"/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </swiper-slide>
            </swiper>
        </div>
        <Loader v-else/>
    </Section>
</template>
